#voice * {
  box-sizing: content-box;
}

@include pc {
  #voice {
    font-size: 16px;
    line-height: 1;
  
    dl {
      margin: 2em 0 0 0;
    }
  
    dl dt {
      width: 1000px;
      height: 140px;
      border: 2px #758186 solid;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;
      margin: 0 0 1em 0;
    }
  
    dl dt:nth-child(1) {
      background-image: url("../assets/pages/home/voice/voice_1.png");
    }
    dl dt:nth-child(3) {
      background-image: url("../assets/pages/home/voice/voice_2.png");
    }
    dl dt:nth-child(5) {
      background-image: url("../assets/pages/home/voice/voice_3.png");
    }
  
    dl dt span {
      width: 1000px;
      height: 140px;
      vertical-align: middle;
      display: table-cell;
      padding-left: 10em;
      text-decoration: none;
    }
  
    dl dt:hover {
      opacity: 0.5;
      filter: alpha(opacity=50);
      -ms-filter: "alpha(opacity=50)";
    }
  
    dl dt span p {
      margin: 0.5em 0;
    }
  
    dl dt span p.title {
      font-size: 1.5em;
      font-weight: bold;
    }
  
    dl dt span p.sub {
      font-size: 1.125em;
    }
  
    dl dt span p.sub::after {
      content: url("../assets/pages/home/voice/arrow_voice_single.png");
  
      float: right;
      display: inline-block;
      position: relative;
      left: -30px;
      top: -16px;
    }
  
    dl dt.active span p.sub::after {
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }
  
    dl dd {
      display: none;
    }
  
    dl dd p {
      font-size: 1em;
      margin: 0 1em 1em 1em;
      line-height: 140%;
    }
  
    dl dd p.desc {
      border-left: 5px #1796d1 solid;
      padding: 0.5em 1em;
      font-weight: bold;
    }
  
    dl dd ul {
      display: flex;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      justify-content: center;
      -ms-justify-content: center;
      -webkit-justify-content: center;
      -webkit-box-pack: justify;
      align-items: center;
      -ms-flex-align: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
    }
  
    dl dd ul li {
      background-repeat: no-repeat;
      background-position: right center;
      padding: 0 2.5em;
      margin: 1em 0 3em 0;
      text-align: center;
      line-height: 140%;
      background-image: url("../assets/pages/home/voice/arrow_voice.png");
    }
  
    dl dd ul li:last-child {
      background-image: none;
    }
  
    dl dd ul li img,
    dl dd ul li span {
      display: inline-block;
      vertical-align: middle;
      position: relative;
      top: -1px;
      margin: 0 0.4em;
    }
  
    dl dd ul li.txt {
      font-size: 1em;
    }
  
    dl dd ul li.txt span {
      font-weight: bold;
    }
  
    dl dd ul li.txt span.sp {
      display: none;
    }
  
    dl dd ul li.judge_1 div {
      background-color: #1796d1;
      padding: 0.9em 1em 0.7em 1em;
      color: #fff;
    }
  
    dl dd ul li.judge_1 div img {
      margin: 0 0.4em 0 0;
      top: -2px;
    }
  
    dl dd ul li.judge_1 div span {
      font-size: 1.75em;
    }
  
    dl dd ul li.judge_2 div {
      border: 2px #1796d1 solid;
      padding: 0.7em 1em;
    }
  
    dl dd ul li.judge_2 div span {
      font-size: 1.25em;
      font-weight: bold;
    }
  }
}

@include sp {
  #voice {
    font-size: 11.2px;

    dl {
      margin: 1em 0 0 0;
    }

    dl dt {
      // width: 95%;
      border: 2px #1796d1 solid;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100%;
      cursor: pointer;
      margin: 0 auto 1em auto;
    }

    dl dt:nth-child(1) {
      background-image: url("../assets/pages/home/voice/voice_1.png");
    }
    dl dt:nth-child(3) {
      background-image: url("../assets/pages/home/voice/voice_2.png");
    }
    dl dt:nth-child(5) {
      background-image: url("../assets/pages/home/voice/voice_3.png");
    }

    dl dt span {
      width: 75%;
      display: block;
      padding: 0 2em 0 16%;
      text-decoration: none;
    }

    dl dt span {
      background-image: url("../assets/pages/home/voice/arrow_voice_single_sp.png");
      background-repeat: no-repeat;
      background-position: right center;
    }

    dl dt span p {
      margin: 0.5em 0;
    }

    dl dt span p.title {
      font-size: 1.25em;
      font-weight: bold;
      line-height: 130%;
    }

    dl dt span p.sub {
      font-size: 1.125em;
    }

    dl dt.active span p.sub::after {
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
    }

    dl dd {
      display: none;
    }

    dl dd p {
      font-size: 1.125em;
      margin: 0 1em 1em 1em;
      line-height: 140%;
    }

    dl dd p.desc {
      border-left: 5px #1796d1 solid;
      padding: 0.5em 1em;
      font-weight: bold;
    }

    dl dd ul.pc {
      display: none;
    }

    img.sp {
      width: 96%;
      display: block;
      margin: 1em auto;
    }

    dl dd ul {
      text-align: center;
      margin-bottom: 4em;
    }

    dl dd ul li:after {
      background-image: url("../assets/pages/home/voice/arrow_voice.png");
      display: block;
      transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      margin: 1em 0;
    }

    dl dd ul li:last-child:after {
      content: "";
    }

    dl dd ul li.txt br,
    dl dd ul li.judge_2 br {
      display: none;
    }

    dl dd ul li.txt {
      font-size: 1.25em;
      padding: 0;
    }

    dl dd ul li.judge_1 div {
      background-color: #1796d1;
      padding: 0.9em 1em 0.7em 1em;
      color: #fff;
      width: 80%;
      margin: 0 auto;
      font-size: 1.25em;
      border-radius: 20px;
    }

    dl dd ul li.judge_1 div img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      top: -1px;
    }

    dl dd ul li.judge_2 div {
      border: 2px #1796d1 solid;
      padding: 1em;
      font-size: 1.5em;
      width: 80%;
      margin: 0 auto;
    }

    dl dd ul li.judge_2 div span {
      font-weight: bold;
    }
  }
}
