@import "../lib/variable";

html,
body {
  font-size: 62.5%;
}

body {
  font-family: 'Noto Sans JP', YuGothic,'Yu Gothic','ヒラギノ角ゴシック','Hiragino Sans','メイリオ', Meiryo,sans-serif;
  font-weight: 400;
  letter-spacing: 0.12em;
  line-height: 1.5;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "palt";
}

.serif {
  font-family: 'Noto Serif JP', serif;
}
