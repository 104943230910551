@import "../lib/mediaquery";
@import "../lib/variable";

.header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #fff;
  z-index: 100;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 94.14%;
    margin: 0 auto;

    @include pc {
      height: 80px;
    }

    @include sp {
      height: 66px;
    }
  }

  &__logo {
    img {
      @include sp {
        width: 118px;
      }
    }
  }

  &__nav {
    @media (min-width: 1367px) {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    @media (max-width: 1366px) {
      display: none;
      position: fixed;
      left: 0;
      background-color: #fff;
      padding: 0 5.33%;
    }

    @media(max-width: 1366px) and (min-width: 769px) {
      top: 80px;
      width: 100%;
      height: calc(100vh - 80px);
    }

    @include sp {
      top: 66px;
      height: calc(100% - 66px);
    }
  }

  &__btn {
    @media (min-width: 1367px) {
      display: flex;
      margin-left: 30px;
    }

    @media(max-width: 1366px) and (min-width: 769px) {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    a {
      display: inline-block;

      @include sp {
        width: 100%;
      }

      &:nth-child(1n+2) {

        @include pc {
          margin-left: 20px;
        }

        @include sp {
          margin-top: 10px;
        }
      }
    }

    img {
      @include sp {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
        width: 100%;
      }

      &.pc-img {
        @media (max-width: 1366px) {
          display: none;
        }
      }

      &.sp-img {
        @media (min-width: 1367px) {
          display: none;
        }
      }
    }
  }
}

.header-nav {
  @media (min-width: 1367px) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  @media (max-width: 1366px) {
    
  }

  @media(max-width: 1366px) and (min-width: 769px) {

  }


  &__item {
    position: relative;


    &:nth-child(1n+2) {
      @media (min-width: 1367px) {
        margin-left: 30px;
      }

      @media (max-width: 1366px) {
        border-top: 1px solid #EAEAEA;
      }
    }
  }

  &__link {
    text-decoration: none;
    font-weight: 700;
    color: #000;
    text-decoration: none;
    letter-spacing: 0.1em;
    transition: all 0.2s linear;
    border-bottom: 1px solid transparent;
    padding: 6px 0;

    @media (min-width: 1367px) {
      font-size: 12px;
    }

    @media (max-width: 1366px) {
      display: block;
      padding: 25px 0;
      font-size: 14px;
      text-align: center;
      color: #228cc2;
    }

    // @media(max-width: 1366px) and (min-width: 769px) {

    // }

    &:hover {
      opacity: 1;

      @media (min-width: 1367px) {
        color: #228cc2;
        border-bottom-color: #228CC2;
      }
    }

    &--sub {
      span {
        padding-right: 16px;
        background-position: right 0 center;
        background-image: url("../assets/common/nav-arrow-bottom.svg");
      }
    }
  }


}


.header-sub-nav {
  background-color: #fff;
  z-index: 20;
  display: none;

  @media (min-width: 1367px) {
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
  }

  @media (max-width: 1366px) {
    
  }

  &__item {

  }

  &__link {
    white-space: nowrap;
    display: block;
    text-decoration: none;
    color: #228cc2;
    letter-spacing: 0.1em;
    padding: 8px;
    transition: all 0.2s linear;
    text-align: center;
    font-weight: 500;

    @media (min-width: 1367px) {
      font-size: 10px;
    }

    @media (max-width: 1366px) {
      font-size: 12px;
    }

    &:hover {
      opacity: 1;
      background-color: #ededed;
    }
  }
}


.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}
.menu-trigger {
  position: relative;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  @media(max-width: 1366px) and (min-width: 769px) {
    width: 48px;
    height: 36px;
  }

  @include sp {
    width: 22px;
    height: 20px;
  }
}
.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: #0075AC;

  @media(max-width: 1366px) and (min-width: 769px) {
    height: 2px;
  }

  @include sp {
    height: 1px;
  }
}
.menu-trigger span:nth-of-type(1) {
  top: 0;
}
.menu-trigger span:nth-of-type(2) {
  @media(max-width: 1366px) and (min-width: 769px) {
    top: 17px;
  }

  @include sp {
    top: 10px;
  }
}
.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger.active span:nth-of-type(1) {
  @media(max-width: 1366px) and (min-width: 769px) {
    transform: translateY(18px) rotate(-45deg);
  }

  @include sp {
    transform: translateY(10px) rotate(-45deg);
  }
}
.menu-trigger.active span:nth-of-type(2) {
  opacity: 0;
}
.menu-trigger.active span:nth-of-type(3) {
  @media(max-width: 1366px) and (min-width: 769px) {
    transform: translateY(-18px) rotate(45deg);
  }

  @include sp {
    transform: translateY(-10px) rotate(45deg);
  }
}


.nav-toggle {
  @media (min-width: 1367px) {
    display: none;
  }

  @media (max-width: 1366px) {
    display: block;

  }
}