@import "../lib/mediaquery";
@import "../lib/variable";

.footer {
  @include sp {
    padding-bottom: 13.36vw;
  }

  &__copyright {
    text-align: center;
    font-size: 11px;
    letter-spacing: 0.12em;
    background-color: #228cc2;
    color: #fff;
    padding: 10px 0;
  }
}



