@import "../lib/mediaquery";

ul,
ol,
li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

area {
  outline: none;
}

a {
  transition: opacity 0.3s linear;

  &:hover {
    @include pc {
      opacity: 0.8;
    }
  }
}

button {
  outline: none;
}
