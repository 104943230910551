
main {
  display: block;
}

@mixin ie($IE-ver: null) {
  @if $IE-ver == '8' { // IE8以下
    @media \0screen\,screen\9 {
      @content;
    }
  }

  @else if $IE-ver == '9' { // IE9以上
    @media screen and (min-width:0\0) {
      @content;
    }
  }

  @else if $IE-ver == '10' { // IE10以上
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  }

  @else if $IE-ver == '11' { // IE11以上
    @at-root _:-ms-fullscreen,:root & {
      @content;
    }
  }

}