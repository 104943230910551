.sp {
  @include mq($pc) {
    display: none !important;
  }
}

.pc {
  @include mq($sp) {
    display: none !important;
  }
}
