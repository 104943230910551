@import "../lib/mediaquery";
@import "../lib/variable";

.section-fv {
  position: relative;

  @include pc {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 80px;
    // height: 43.92vw;
    // min-height: 600px;
    // background-image: url("../assets/pages/home/fv-pc.jpg");
    height: 600px;
    background-size: cover;
    background-position: right 0 bottom 0;
    background: transparent radial-gradient(closest-side at 50% 50%, #008DCC 0%, #0074BE 100%) 0% 0% no-repeat padding-box;
  }

  @include sp {
    margin-top: 66px;
    height: 172.8vw;
    background: radial-gradient(circle, rgba(0,141,204,1) 0%, rgba(0,116,190,1) 100%);
  }

  &__container {
    @include pc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
      max-width: 1269px;
    }
  }

  &__title-area {
    @include pc {
      margin-bottom: 50px;
    }

    @include sp {
      position: absolute;
      width: 82.93%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__image {
    display: block;

    @include pc {
      max-width: 52.69%;
      width: calc(100% - 510px);
      align-self: flex-end;
    }

    @include sp {
      width: 100%;
      // margin-top: 42px;
    }
  }

  &__btn {
    @include pc {
      margin-top: 50px;
    }

    @include sp {
      display: block;
    }

    a {
      @include sp {
        display: block;
      }

      &:nth-child(1n+2) {
        @include sp {
          margin-top: 15px;
        }
      }
    }

    img {
      @include sp {
        display: block;
        width: 100%;
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
      }
    }
  }
}

.section-6-service {
  background-color: #fafafa;

  @include pc {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  @include sp {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__container {
    @include pc {
      margin-top: 40px;
    }

    @include sp {
      margin-top: 30px;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
}

.section-service-flow {
  background-color: #fafafa;

  @include pc {
    padding-top: 50px;
    padding-bottom: 75px;
  }

  @include sp {
    padding-top: 25px;
    padding-bottom: 50px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      margin-top: 45px;
      max-width: 984px;
    }

    @include sp {
      margin-top: 20px;
      width: 86.66%;
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .section-pamphlet {
    margin-top: 72px;
  }
}

.section-pamphlet {
  @include pc {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include sp {
    
  }

  &__image {
    display: block;

    @include pc {
      width: 47.4%;
    }

    @include sp {
      width: 100%;
    }
  }

  &__text {
    font-weight: 500;
    letter-spacing: 0.08em;
    line-height: 2.1;

    @include pc {
      width: 48.42%;
      font-size: 15px;
    }

    @include sp {
      font-size: 13px;
      margin-top: 15px;
    }
  }

  .btn {
    margin-top: 20px;

    @include pc {
      display: inline-block;
    }

    @include sp {
      display: block;
      width: 325px;
      margin: 20px auto 0 auto;
    }
  }
}

.section-early-detection {
  @include pc {
    padding-top: 90px;
  }

  @include sp {
    padding-top: 50px;
  }

  &__container {
    margin-top: 30px;
  }

  &__image {
    display: block;
    margin: 0 auto;
  }
}

.section-medical-checkup {
  @include pc {
    margin-top: 100px;
  }

  @include sp {
    margin-top: 50px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      margin-top: 34px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
  }
}

.section-slow {
  @include pc {
    margin-top: 100px;
  }

  @include sp {
    margin-top: 50px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      margin-top: 25px;
      max-width: 986px;
    }

    @include sp {
      margin-top: 20px;
      width: 86.66%;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;
  }

  .section-pamphlet {
    @include pc {
      margin-top: 68px;
    }

    @include sp {
      margin-top: 50px;
    }
  }
}

.section-verification {
  background-color: #fafafa;

  @include pc {
    margin-top: 75px;
    padding-top: 100px;
    padding-bottom: 50px;
  }

  @include sp {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__container {
    @include pc {
      margin-top: 40px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__subtitle {
    font-weight: 700;
    letter-spacing: 0.08em;
    text-align: center;

    @include pc {
      font-size: 22px;
    }

    @include sp {
      font-size: 18px;
    }

    span {
      color: #228cc2;
    }
  }

  &__text {
    text-align: center;
    letter-spacing: 0.06em;
    line-height: 2;

    @include pc {
      margin-top: 30px;
      font-size: 15px;
    }

    @include sp {
      margin-top: 10px;
      font-size: 14px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      margin-top: 40px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.section-trouble {
  background-color: #fafafa;

  @include pc {
    padding: 50px 0;
  }

  @include sp {
    padding: 25px 0;
  }

  &__container {
    @include pc {
      margin-top: 30px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 896px;
    }

    @include sp {
      width: 89.86%;
    }
  }
}

.section-inspection-results {
  background-color: #fafafa;

  @include pc {
    padding: 50px 0 100px 0;
  }

  @include sp {
    padding: 25px 0 50px 0;
  }

  &__container {
    @include pc {
      margin-top: 30px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 986px;
    }

    @include sp {
      width: 86.66%;
    }
  }

  &__text {
    text-align: center;
    line-height: 1.6;

    @include pc {
      margin-top: 20px;
      font-size: 13px;
      letter-spacing: 0.06em;
    }

    @include sp {
      margin-top: 10px;
      letter-spacing: 0;
      font-size: 12px;
    }
  }

  &__risk {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 986px;
      padding-top: 100px;
    }

    @include sp {
      width: 86.66%;
      padding-top: 50px;
    }
  }

  &__profile {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 998px;
      margin-top: 100px;
    }

    @include sp {
      width: 86.66%;
      margin-top: 50px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);
    }

    object {
      display: block;
      width: 100%;
    }
  }
}

.section-free-inspection {
  @include pc {
    padding-top: 100px;
  }

  @include sp {
    padding-top: 50px;
  }

  &__container {
    margin-top: 30px;

    @include sp {
      width: 86.66%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 986px;
    }

    @include sp {
      width: 100%;
    }
  }
}

.section-post {
  @include pc {
    padding-top: 100px;
  }

  @include sp {
    padding-top: 50px;
  }

  &__container {
    @include pc {
      margin-top: 30px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      max-width: 795px;
    }

    @include sp {
      width: 86.66%;
    }
  }
}

.section-choices {
  .section-contact-link {
    @include pc {
      margin-top: 100px;
    }

    @include sp {
      width: 86.66%;
      margin-top: 50px;
    }
  }
}

.section-membership-fee {
  @include pc {
    padding-top: 80px;
    padding-bottom: 36px;
  }

  @include sp {
    padding-top: 50px;
    padding-bottom: 25px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      padding: 0 32px;
      max-width: 1050px;
      margin-top: 30px;
    }

    @include sp {
      width: 86.93%;
      margin-top: 20px;
    }
  }

  &__table-swipe {
    display: block;
    margin-top: 10px;
  }

  &__image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }

  &__table {
    display: block;
    margin: 0 auto;

    @include sp {
      overflow-x: scroll;
      margin-right: -7.1%;
    }

    img {
      display: block;

      @include pc {
        max-width: 100%;
      }

      @include sp {
        width: 712px;
      }
    }
  }

  &__content {
    max-width: 100%;
    margin: 0 auto;

    @include pc {
      margin-top: 35px;
    }

    @include sp {
      width: 100%;
      margin-top: 20px;
    }
  }

  .section-contact-link-02 {
    @include pc {
      margin-top: 80px;
    }

    @include sp {
      margin-top: 50px;
    }
  }
}

.section-membership-plan {
  @include pc {
    padding-top: 36px;
  }

  @include sp {
    margin-top: 20px;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      max-width: 986px;
    }

    @include sp {
      width: 86.66%;
    }
  }

  &__image {
    display: block;
    margin: 0 auto;

    @include pc {
      margin-top: 50px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__plan-table {
    display: block;
    margin: 0 auto;
    margin-top: 50px;
  }

  &__content {
    display: block;
    margin: 0 auto;
    max-width: 100%;

    @include pc {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.section-user-voice {
  background-color: #fafafa;

  @include pc {
    padding: 80px 0 40px 0;
    margin-top: 90px;
  }

  @include sp {
    margin-top: 50px;
    padding: 50px 0 25px 0;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      margin-top: 30px;
      max-width: 986px;
    }

    @include sp {
      width: 86.66%;
      margin-top: 15px;
    }
  }
}

.section-faq {
  background-color: #fafafa;

  @include pc {
    margin-top: 100px;
    padding: 40px 0;
  }

  @include sp {
    margin-top: 50px;
    padding: 25px 0;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      margin-top: 35px;
      max-width: 917px;
    }

    @include sp {
      margin-top: 18px;
      width: 87.46%;
    }
  }

  .section-contact-link-02 {
    @include pc {
      margin-top: 60px;
    }

    @include sp {
      margin-top: 50px;
    }
  }
}

.section-faq-block {
  &:nth-child(1n+2) {
    @include pc {
      margin-top: 50px;
    }

    @include sp {
      margin-top: 30px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    letter-spacing: 0.08em;
    color: #228cc2;
    font-weight: 700;

    @include pc {
      font-size: 22px;
    }

    @include sp {
      font-size: 15px;
    }

    img {
      margin-right: 10px;
    }
  }

  &__content {
    @include pc {
      margin-top: 25px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}


.section-faq-item {
  position: relative;
  background-color: #fff;
  background-image: url("../assets/pages/home/icon-q.svg");
  background-position: left 0 top 0;

  @include pc {
    border-radius: 16px;
    padding: 0 30px 20px 30px;
  }

  @include sp {
    border-radius: 10px;
    background-size: 35px;
    padding: 0 10px 10px 10px;
  }

  &:nth-child(1n+2) {
    @include pc {
      margin-top: 20px;
    }

    @include sp {
      margin-top: 15px;
    }
  }

  &__panel {
    font-weight: 700;
    color: #228cc2;
    letter-spacing: 0.1em;
    cursor: pointer;

    @include pc {
      padding: 18px 16px 0 25px;
      font-size: 15px;
    }

    @include sp {
      padding: 10px 35px 0 36px;
      font-size: 12px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      background-position: center;
      background-image: url("../assets/pages/home/icon-plus.svg");

      @include pc {
        top: 20px;
        width: 17px;
        height: 17px;
        right: 20px;
      }

      @include sp {
        top: 12px;
        width: 15px;
        height: 15px;
        right: 15px;
      }
    }

    &.active::before {
      background-image: url("../assets/pages/home/icon-minus.svg");
    }
  }

  &__content {
    display: none;
    background-color: #eff6fa;
    margin-top: 18px;
    padding: 20px 25px 20px 58px;
    background-image: url("../assets/pages/home/icon-a.svg");
    background-position: left 24px top 28px;
    border-radius: 16px;

  }

  &__text {
    font-size: 12px;
    letter-spacing: 0.06em;
    line-height: 2;
  }
}

.section-concierge {
  @include pc {
    padding-top: 100px;
  }

  @include sp {
    padding-top: 50px;
  }

  &__icon {
    display: block;
    margin: 0 auto;
  }

  &__desc {
    text-align: center;
    line-height: 2;

    @include pc {
      margin-top: 10px;
      font-size: 15px;
      letter-spacing: 0.05em;
    }

    @include sp {
      margin-top: 15px;
      letter-spacing: 0;
      font-size: 12px;
    }
  }

  .section-title {
    @include pc {
      margin-top: 15px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__container {
    @include pc {
      margin-top: 50px;
    }

    @include sp {
      margin-top: 30px;
    }
  }
}

.section-concierge-item {
  @include pc {
    padding: 40px 0;
  }

  @include sp {
    padding: 30px 0;
  }

  &:nth-child(odd) {
    background-color: #fafafa;
  }

  &__container {
    margin: 0 auto;

    @include pc {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 32px;
      max-width: 972px;
    }

    @include sp {
      width: 86.93%;
    }

    &--right {
      @include pc {
        flex-direction: row-reverse;
      }
    }
  }

  &__image {
    border-radius: 10px;

    @include pc {
      width: 45.92%;
    }

    @include sp {
      width: 100%;
    }

    &--no-border {
      border-radius: 0;
    }
  }

  &__content {
    @include pc {
      width: 48.45%;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__title {
    position: relative;
    font-weight: 700;
    color: #228cc2;
    letter-spacing: 0.12em;
    padding-bottom: 15px;

    @include pc {
      font-size: 20px;
    }

    @include sp {
      font-size: 18px;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 25px;
      height: 1px;
      background-color: #c6c6c6;
    }
  }

  &__text {
    line-height: 2;
    margin-top: 15px;

    @include pc {
      font-size: 14px;
      letter-spacing: 0.12em;
    }

    @include sp {
      font-size: 12px;
      letter-spacing: 0.06em;
    }

    small {
      display: inline-block;
      margin-top: 10px;
      line-height: 1.5;
      font-size: 10px;
      letter-spacing: 0.06em;
    }
  }
}


.section-arrange {
  .section-concierge-item__content {
    @include sp {
      margin-top: 0;
    }

    img {
      @include sp {
        display: block;
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

.section-note {
  background-color: #fafafa;

  @include pc {
    padding: 40px 0 80px 0;
  }

  @include sp {
    padding: 25px 0 50px 0;
  }

  &__container {
    margin: 0 auto;
    border-top: 1px solid #ccc;

    @include pc {
      max-width: 982px;
      padding: 40px 32px 0 32px;
    }

    @include sp {
      padding-top: 20px;
      width: 86.93%;
    }
  }

  &__title {
    letter-spacing: 0.08em;
    font-weight: 700;

    @include pc {
      font-size: 20px;
    }

    @include sp {
      font-size: 16px;
    }
  }

  &__content {
    @include pc {
      margin-top: 25px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}

.section-note-item {
  &:nth-child(1n+2) {
    @include pc {
      margin-top: 25px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__title {
    letter-spacing: 0.08em;
    font-weight: 700;

    @include pc {
      font-size: 15px;
    }

    @include sp {
      font-size: 12px;
    }
  }

  &__text {
    font-weight: 400;
    letter-spacing: 0.06em;
    line-height: 1.8;

    @include pc {
      margin-top: 10px;
      font-size: 12px;
    }

    @include sp {
      margin-top: 7px;
      font-size: 10px;
    }

    strong {
      display: block;
    }

    &:nth-of-type(1n+2) {
      margin-top: 20px;
    }
  }
}

.fixed-btn {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  a {
    display: block;
    width: 50%;
  }

  img {
    display: block;
    width: 100%;
  }
}
