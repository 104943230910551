@import "../lib/mediaquery";
@import "../lib/variable";

.section-title {
  text-align: center;
  line-height: 1.75;
  font-weight: 700;
  letter-spacing: 0.08em;

  @include pc {
    font-size: 26px;
  }

  @include sp {
    font-size: 18px;
  }

  span {
    color: #228CC2;
  }
}

.section-title-02 {
  text-align: center;
  color: #fff;
  background-color: #5583ab;
  font-weight: 500;
  letter-spacing: 0.12em;

  @include pc {
    font-size: 20px;
    padding: 15px 0;
  }

  @include sp {
    padding: 10px 0;
    font-size: 16px;
  }
}

.section-contact-link-02 {
  margin: 0 auto;
  background: transparent radial-gradient(closest-side at 50% 50%, #0088C5 0%, #0074BE 100%) 0% 0% no-repeat padding-box;

  @include pc {
    max-width: 986px;
    padding: 30px 52px;
  }

  @include sp {
    padding: 22px 14px 46px 14px;
  }

  &__header {
    @include pc {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      flex-direction: row-reverse;
    }
  }

  &__title {
    @include pc {
      width: 62.34%;
    }

    @include sp {
      width: 100%;
      margin-top: 12px;
    }
  }

  &__image {
    @include pc {
      width: 38.51%;
      margin-left: -0.85%;
    }

    @include sp {
      display: block;
      width: 100%;
    }
  }

  &__footer {
    @include pc {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-top: 30px;
    }

    @include sp {
      margin-top: 20px;
    }
  }

  &__btn {
    @include pc {
      display: flex;
    }

    a {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.16);

      @include sp {
        display: block;
      }

      &:nth-child(1n+2) {
        @include pc {
          margin-left: 20px;
        }

        @include sp {
          margin-top: 10px;
        }
      }

      img {
        @include sp {
          display: block;
          width: 100%;
        }
      }
    }
  }

  &__tel {
    @include sp {
      display: block;
      width: 100%;
      margin-top: 24px;
    }
  }
}

.section-contact-link {
  margin: 0 auto;
  background: transparent radial-gradient(closest-side at 50% 50%, #0088C5 0%, #0074BE 100%) 0% 0% no-repeat padding-box;

  @include pc {
    max-width: 986px;
    padding: 35px 0 30px 0;
  }

  @include sp {
    padding: 30px 0 20px 0;
  }

  &__price {
    display: block;
    margin: 0 auto;
  }

  &__btn {
    display: flex;

    @include pc {
      justify-content: center;
      align-items: flex-start;
      margin-top: 34px;
    }

    @include sp {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 20px;
    }

    a {
      display: block;

      &:nth-child(1n+2) {
        @include pc {
          margin-left: 20px;
        }

        @include sp {
          margin-top: 15px;
        }
      }
    }

    img {
      display: block;
    }
  }

  &__tel {
    display: block;
    margin: 0 auto;

    @include pc {
      margin-top: 34px;
    }

    @include sp {
      margin-top: 20px;
    }
  }
}


