$pc: 'min-width: 769px';
$sp: 'max-width: 768px';

$pc-min: 'min-width: 769px';
$sp-max: 'max-width: 769px';
$sp-min: 'max-width: 321px';

@mixin mq($query) {
  @media screen and ($query) {
    @content;
  }
}

@mixin pc() {
  @media screen and ($pc) {
    @content;
  }
}

@mixin sp() {
  @media screen and ($sp) {
    @content;
  }
}

@mixin sp-min() {
  @media screen and ($sp-min) {
    @content;
  }
}

